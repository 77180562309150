<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="80%">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs">
        <el-card>
          <div slot="header" class="clearfix">
            <p>行驶证信息</p>
          </div>
          <l-preview :data="dataInfo" :span="12" :columns="dataColumns"> </l-preview>
        </el-card>
      </el-col>
      <el-col :span="8" class="padding-xs">
        <el-card>
          <div slot="header" class="clearfix">
            <p>保养信息</p>
          </div>
          <l-preview :data="dataInfo" :columns="dataColumns2"> </l-preview>
        </el-card>
      </el-col>
      <el-col :span="8" class="padding-xs">
        <el-card>
          <div slot="header" class="clearfix">
            <p>保险信息</p>
          </div>
          <l-preview :data="dataInfo" :columns="dataColumns3"> </l-preview>
        </el-card>
      </el-col>
      <el-col :span="8" class="padding-xs">
        <el-card>
          <div slot="header" class="clearfix">
            <p>年检信息</p>
          </div>
          <l-preview :data="dataInfo" :columns="dataColumns4"> </l-preview>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '车牌号', prop: 'carNo' },
        { label: '品牌/车系', prop: 'carBrandSeries' },
        { label: '车架号', prop: 'vin' },
        { label: '发动机号', prop: 'engineNo' },
        { label: '车辆类型', prop: 'vehicleType' },
        { label: '使用性质', prop: 'useCharacter' },
        { label: '所有人', prop: 'owner' },
        { label: '地址', prop: 'address' },
        { label: '车牌颜色', prop: 'licenceColor' },
        { label: '注册日期', prop: 'registerDate', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '发证日期', prop: 'issueDate', filter: a => this.$lgh.limitTo(a, 10) }
      ],
      dataColumns2: [
        { label: '当前公里数', prop: 'milage' },
        { label: '最新保养公里数', prop: 'maintenanceMilage' },
        { label: '最新保养日期', prop: 'maintenanceDate', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '下次保养公里数', prop: 'nextMaintenanceMilage' },
        { label: '下次保养日期', prop: 'nextMaintenanceDate', filter: a => this.$lgh.limitTo(a, 10) }
      ],
      dataColumns3: [
        { label: '保单号', prop: 'insuranceOrderCode' },
        { label: '保险公司', prop: 'insuranceCompany' },
        { label: '商业险额度', prop: 'insuranceAmount' },
        { label: '保险开始日期', prop: 'insuranceStartDate', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '保险结束日期', prop: 'insuranceEndDate', filter: a => this.$lgh.limitTo(a, 10) }
      ],
      dataColumns4: [
        { label: '最近年检日期', prop: 'yearlyInspectDate', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '下次年检日期', prop: 'nextYearlyInspectDate', filter: a => this.$lgh.limitTo(a, 10) }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
